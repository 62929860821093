<template>
  <v-navigation-drawer permanent color="#272727" style="color:#fff" id="core-navigation-drawer" app
    :right="$vuetify.rtl" :dark="'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular">
          <v-row align="center" justify="center">
            <v-col align="center" justify="center" cols="12" md="6">
              <h4 style="color:#fff"> التحكم </h4>
            </v-col>
            <v-col class="text-center">
              <!-- <img width="31px" src="../../assets/project-management.svg" /> -->
            </v-col>

          </v-row>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    {{changeLang()}}
    <!-- <v-divider class="mb-1" /> -->

    <v-list-item two-line>


      <v-list-item-avatar>
        <img
          src="https://cdn1.vectorstock.com/i/1000x1000/51/05/male-profile-avatar-with-brown-hair-vector-12055105.jpg" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title style="color:#fff">{{ $store.state.AdminInfo.name }}</v-list-item-title>
        <v-list-item-subtitle style="color:#fff">{{
         $store.state.AdminInfo.name
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-divider light style="color#fff" /> -->


    <v-list dense nav>
      <v-list-item style="color:#fff !important" v-for="item in items" :key="item.title" link :to="item.to">
        <v-list-item-icon>
          <v-icon style="color:#fff !important">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {

    data() {
      return {
        items: [
           {
            title: 'articles',
            icon: 'fas fa-newspaper',
            to: "/articles"
          },

          {
            title: 'السيرة',
            icon: 'fas fa-user-secret',
            to: "/seera"
          },
          {
            title: 'videos',
            icon: 'fas fa-newspaper',
            to: "/videos"
          },
          {
            title: 'gallery',
            icon: 'fas fa-newspaper',
            to: "/gallery"
          },
          {
            title: 'zyara',
            icon: 'fas fa-newspaper',
            to: "/zyara"
          },
          {
            title:'callUs',
            icon:'fas fa-newspaper',
            to:"/contactus"
          },
          {
            title: 'details',
            icon: 'fas fa-newspaper',
            to: "/details"
          },
          {
            title: 'info',
            icon: 'fas fa-newspaper',
            to: "/info"
          },

        ],
        right: null,
      }
    },
    methods: {
      changeLang() {
        this.items[0].title = this.$t("Drawer.news")
         
        this.items[1].title = this.$t("Drawer.seera")
     
        this.items[2].title = this.$t("Drawer.videos")
        
        this.items[3].title = this.$t("Drawer.libraryImage")
        this.items[4].title = this.$t("Drawer.zyara")

        this.items[5].title = this.$t("Drawer.callUs")
        this.items[6].title = this.$t("Drawer.details")
        this.items[7].title = this.$t("Drawer.info")
      },
    },
  }
</script>